<template>
  <v-container>
    <v-card>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header-ex label="デザインイメージ"></v-expansion-panel-header-ex>

          <v-expansion-panel-content>
            <v-row>
              <!-- ロゴ -->
              <v-col cols="8">
                <v-card-title>ロゴ</v-card-title>
                <logo-add></logo-add>
              </v-col>

              <!-- 医院カラー -->
              <v-col cols="4">
                <v-card-title>医院カラー</v-card-title>
                <theme-colors></theme-colors>
              </v-col>
            </v-row>

            <!-- 医院内装 -->
            <v-card-title>医院内装</v-card-title>
            <reference-image></reference-image>

            <!-- テイスト・こだわり -->
            <v-card-text>
              <v-textarea-with-link label="テイスト・こだわり" ></v-textarea-with-link>
            </v-card-text>


          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>
<script>
import VTextareaWithLink from '../../components/atomic/atoms/VTextareaWithLink.vue';
import VExpansionPanelHeaderEx from '../../components/atomic/atoms/VExpansionPanelHeaderEx.vue';

import LogoAdd from '../../components/atomic/molecules/LogoAdd.vue'
import ThemeColors from '../../components/atomic/molecules/ThemeColors.vue'
import ReferenceImage from '../../components/atomic/molecules/ReferenceImage.vue'
export default {
  components: {
    VTextareaWithLink,
    VExpansionPanelHeaderEx,
    LogoAdd,
    ThemeColors,
    ReferenceImage,
  },
}
</script>
